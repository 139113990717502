import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Button from "@atoms/Button/Button";
import Divider from "@atoms/Divider/Divider";
import Section from "@atoms/Section/Section";
import Title from "@atoms/Title/Title";
import Footer from "@components/Footer/Footer";
import HeaderMinimal from "@components/HeaderMinimal/HeaderMinimal";
import HeaderNav from "@components/HeaderNavV2/HeaderNav";
import Layout from "@components/layout";
import Seo from "@components/seo";
import SingleAccordion from "@components/SingleAccordion/SingleAccordion";
import { withLocale, withTracking } from "@hocs";
import { useTracking } from '@hooks';

export const query = graphql`
    query($language: String!, $customLangKey: AEPSYCMS_I18NLocaleCode!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "faq/header-FAQ-mobile.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "faq/header-FAQ-desktop.jpg" }
        ) {
            childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            faqLang: faqs(
                locale: $customLangKey
                filters: { key: { eq: "FOR_PSYCHOLOGIST" } }
            ) {
                ...FaqField
            }
            faqsUserLang: faqs(
                locale: $customLangKey
                filters: { key: { eq: "FOR_USER" } }
            ) {
                ...FaqField
            }
        }
    }
`;

const FAQPage: React.FC<any> = ({ data }) => {
	const { t } = useTranslation();
	const faqForTherapist = data.aepsyCms.faqLang[0]?.qar;
	const faqForUser = data.aepsyCms.faqsUserLang[0]?.qar;

	const static_images_sources = [
		data.headerMobileImage.childImageSharp.fluid,
		{
			...data.headerDesktopImage.childImageSharp.fluid,
			media: `(min-width: 750px)`,
		},
	];

	const { trackPageView } = useTracking();
	useEffect(() => {
		trackPageView();
	}, []);

	return (
		<Layout>
			<Seo title="FAQ | Aepsy" />
			<div className="global_theme-green">
				<HeaderNav theme="white" />
				<Section theme="light">
					<HeaderMinimal
						theme="light"
						title={t("faq.page.title")}
						imageSrc={static_images_sources}
						btnPrimary={
							<AnchorLink offset={() => 40} href="#mehr">
								<Button
									variant="primary"
									theme="white"
									label={t("learn.more")}
									size="l"
								/>
							</AnchorLink>
						}
					/>
				</Section>

				<div id="mehr">
					<Section spacing="m" theme="light" container="short">
						{/* FAQ */}
						<Section>
							<Title tag="h2" size="xxl" align="center" theme="dark">
								{t("faq.for.user.title")}
							</Title>
							<Divider invisible spacing="m" />
							{faqForUser && (
								<SingleAccordion data={faqForUser} comingFrom="buchen" />
							)}
						</Section>
					</Section>
					<Section spacing="m" theme="light" container="short">
						<Section>
							<Title tag="h2" size="xxl" align="center" theme="dark">
								{t("faq.for.psychologist.title")}
							</Title>
							<Divider invisible spacing="xl" />
							{faqForTherapist && (
								<SingleAccordion
									data={faqForTherapist}
									comingFrom="therapist"
								/>
							)}
						</Section>
					</Section>
				</div>

				{/* more Questions */}
				<Section container="short" spacing="l">
					<Title tag="h3" size="xl" align="center" theme="dark">
						{t("faq.more.questions")}
					</Title>
					<Divider invisible spacing="s" />
					<Title tag="p" size="m" align="center">
						{t("faq.contact.us.at")}
					</Title>
					<div className="g_center">
						<a href="mailto:hey@aepsy.com">
							<Button label="hey@aepsy.com" theme="highlighted" size="l" />
						</a>
					</div>
				</Section>

				{/* Footer */}
				<Footer variant="extended" />
			</div>
		</Layout>
	);
};

export default withTracking(withLocale(FAQPage));
